import * as defaultData from "../../data/DefaultData";

export const SHXTO = {
  base_info: {
    product_type: {
      type: "select",
      label: "Product Type",
      tips_value: "",
      options_value: defaultData.ProductTypeData,
      value: "",
      disabled: false,
      display: true,
      eventHandle: "productTypeChange",
    },
    print_title: {
      type: "input",
      label: "Print Title",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    product_name: {
      type: "input",
      label: "Product Name",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    customer_product_name: {
      type: "input",
      label: "Customer Product Name",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    item_code: {
      type: "input",
      label: "Item Code",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    product_description: {
      type: "textarea",
      label: "Product Description",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    version_number: {
      type: "input",
      label: "Version Number",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    processing: {
      type: "input",
      label: "Processing",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    physical_properties: {
      type: "select",
      label: "Physical Properties",
      tips_value: "",
      options_value: defaultData.PhysicalPropertiesData,
      value: "",
      disabled: false,
      display: true,
    },
    confidential: {
      type: "checkbox",
      label: "Confidential",
      tips_value: false,
      value: "",
      disabled: false,
      display: true,
      checked: false,
    },
    custom_formula: {
      type: "checkbox",
      label: "Custom Formula",
      tips_value: false,
      value: "",
      disabled: false,
      display: true,
      checked: false,
    },
    document_notes: {
      type: "input",
      label: "Document Notes",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    custom_field: {
      type: "custom_input",
      label: "Custom #:",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
      fields: "",
      attr: "custom_field",
    },
  },
  general_specification: {
    common_other_name: {
      type: "tag",
      label: "Common Other Name",
      tips_value: [],
      value: "",
      disabled: false,
      display: true,
    },
    inci_name: {
      type: "tag",
      label: "Inci Name",
      tips_value: [],
      value: "",
      disabled: false,
      display: true,
    },
    botanical_name: {
      type: "tag",
      label: "Botanical Name",
      tips_value: [],
      value: "",
      disabled: false,
      display: true,
    },
    part_used: {
      type: "tag",
      label: "Part Used",
      tips_value: [],
      value: "",
      disabled: false,
      display: true,
    },
    country_of_origin: {
      type: "input",
      label: "Country Of Origin",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    custom_field_before_regular_data: {
      type: "custom_input",
      label: "Custom #:",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
      fields: "",
      attr: "custom_field_before_regular_data",
    },
    appearance: {
      type: "input",
      label: "Appearance",
      tips_value: "Viscous liquid",
      value: "",
      disabled: false,
      display: true,
    },
    custom_field: {
      type: "custom_input",
      label: "Custom #:",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
      fields: "",
      attr: "gs_custom_field",
    },
  },
  analysis: {
    acid_value: {
      type: "input",
      label: "Acid Value",
      tips_value: {
        specification: "NMT 10 mg KOH/g",
        test_method: "AOCS Cd 3d-63",
      },
      value: "",
      disabled: false,
      display: true,
    },
    peroxide_value: {
      type: "input",
      label: "Peroxide Value",
      tips_value: {
        specification: "NMT 10.0 meq/kg",
        test_method: "AOCS Cd 8b-90",
      },
      value: "",
      disabled: false,
      display: true,
    },
    saponification_value: {
      type: "input",
      label: "Saponification Value",
      tips_value: { specification: "mg KOH/g", test_method: "AOCS Cd 3-25" },
      value: "",
      disabled: false,
      display: true,
    },
    unsaponifiable_master: {
      type: "input",
      label: "Unsaponifiable Master",
      tips_value: { specification: "%", test_method: "AOCS Ca 6a-40" },
      value: "",
      disabled: false,
      display: true,
    },
    moisture_volatiles: {
      type: "input",
      label: "Moisture Volatiles",
      tips_value: { specification: "%", test_method: "AOCS Ca 2d-25" },
      value: "",
      disabled: false,
      display: true,
    },
    total_heavy_metals: {
      type: "input",
      label: "Total Heavy Metals",
      tips_value: { specification: "<20 ppm", test_method: "USP<233>" },
      value: "",
      disabled: false,
      display: true,
    },
    arsenic: {
      type: "input",
      label: "Arsenic",
      tips_value: { specification: "<1.0 ppm", test_method: "AOAC 986.15" },
      value: "",
      disabled: false,
      display: true,
    },
    cadmium: {
      type: "input",
      label: "Cadmium",
      tips_value: { specification: "<1.0 ppm", test_method: "AOAC 986.15" },
      value: "",
      disabled: false,
      display: true,
    },
    lead: {
      type: "input",
      label: "Lead",
      tips_value: { specification: "<1.0 ppm", test_method: "AOCS Ca 18c-91" },
      value: "",
      disabled: false,
      display: true,
    },
    mercury: {
      type: "input",
      label: "Mercury",
      tips_value: { specification: "<1.0 ppm", test_method: "AOAC 971.21" },
      value: "",
      disabled: false,
      display: true,
    },
    pesticides: {
      type: "input",
      label: "Pesticides",
      tips_value: {
        specification: "Conforms to USP<565>",
        test_method: "USP<565>",
      },
      value: "",
      disabled: false,
      display: true,
    },
    color: {
      type: "input",
      label: "Color",
      tips_value: { specification: "", test_method: "Visual" },
      value: "",
      disabled: false,
      display: true,
    },
    odor: {
      type: "input",
      label: "Odor",
      tips_value: {
        specification: "Characteristic",
        test_method: "Organoleptic",
      },
      value: "",
      disabled: false,
      display: true,
    },
    taste: {
      type: "input",
      label: "Taste",
      tips_value: {
        specification: "Characteristic",
        test_method: "Organoleptic",
      },
      value: "",
      disabled: false,
      display: true,
    },
    custom_field: {
      type: "custom_input",
      label: "Custom #:",
      tips_value: { specification: "", test_method: "" },
      value: "",
      disabled: false,
      display: true,
      fields: "",
      attr: "an_custom_field",
    },
  },
  fatty_acid: {
    caprylic: {
      type: "input",
      label: "Caprylic",
      tips_value: { specification: "", test_method: "AOCS Ce 1e-91" },
      value: "",
      disabled: false,
      display: true,
    },
    capric: {
      type: "input",
      label: "Capric",
      tips_value: { specification: "", test_method: "AOCS Ce 1e-91" },
      value: "",
      disabled: false,
      display: true,
    },
    lauric: {
      type: "input",
      label: "Lauric",
      tips_value: { specification: "", test_method: "AOCS Ce 1e-91" },
      value: "",
      disabled: false,
      display: true,
    },
    myristic: {
      type: "input",
      label: "Myristic",
      tips_value: { specification: "", test_method: "AOCS Ce 1e-91" },
      value: "",
      disabled: false,
      display: true,
    },
    myristoleic: {
      type: "input",
      label: "Myristoleic",
      tips_value: { specification: "", test_method: "AOCS Ce 1e-91" },
      value: "",
      disabled: false,
      display: true,
    },
    palmitica: {
      type: "input",
      label: "Palmitica",
      tips_value: { specification: "", test_method: "AOCS Ce 1e-91" },
      value: "",
      disabled: false,
      display: true,
    },
    palmitoleic: {
      type: "input",
      label: "Palmitoleic",
      tips_value: { specification: "", test_method: "AOCS Ce 1e-91" },
      value: "",
      disabled: false,
      display: true,
    },
    stearic: {
      type: "input",
      label: "Stearic",
      tips_value: { specification: "", test_method: "AOCS Ce 1e-91" },
      value: "",
      disabled: false,
      display: true,
    },
    oleic: {
      type: "input",
      label: "Oleic",
      tips_value: { specification: "", test_method: "AOCS Ce 1e-91" },
      value: "",
      disabled: false,
      display: true,
    },
    linoleic_acid: {
      type: "input",
      label: "Linoleic Acid",
      tips_value: { specification: "", test_method: "AOCS Ce 1e-91" },
      value: "",
      disabled: false,
      display: true,
    },
    a_linolenic: {
      type: "input",
      label: "A Linolenic",
      tips_value: { specification: "", test_method: "AOCS Ce 1e-91" },
      value: "",
      disabled: false,
      display: true,
    },
    y_linoleic: {
      type: "input",
      label: "Y Linoleic",
      tips_value: { specification: "", test_method: "AOCS Ce 1e-91" },
      value: "",
      disabled: false,
      display: true,
    },
    punicic_acid: {
      type: "input",
      label: "Punicic Acid",
      tips_value: { specification: "", test_method: "AOCS Ce 1e-91" },
      value: "",
      disabled: false,
      display: true,
    },
    arachidic: {
      type: "input",
      label: "Arachidic",
      tips_value: { specification: "", test_method: "AOCS Ce 1e-91" },
      value: "",
      disabled: false,
      display: true,
    },
    arachidonic: {
      type: "input",
      label: "Arachidonic",
      tips_value: { specification: "", test_method: "AOCS Ce 1e-91" },
      value: "",
      disabled: false,
      display: true,
    },
    eicosapentaenoic: {
      type: "input",
      label: "Eicosapentaenoic",
      tips_value: { specification: "", test_method: "AOCS Ce 1e-91" },
      value: "",
      disabled: false,
      display: true,
    },
    docosahexaenoic: {
      type: "input",
      label: "Docosahexaenoic",
      tips_value: { specification: "", test_method: "AOCS Ce 1e-91" },
      value: "",
      disabled: false,
      display: true,
    },
    others: {
      type: "input",
      label: "Others",
      tips_value: { specification: "", test_method: "AOCS Ce 1e-91" },
      value: "",
      disabled: false,
      display: true,
    },
    custom_field: {
      type: "custom_input",
      label: "Custom #:",
      tips_value: { specification: "", test_method: "" },
      value: "",
      disabled: false,
      display: true,
      fields: "",
      attr: "fa_custom_field",
    },
  },
  microbiological_tests: {
    total_plate_count: {
      type: "input",
      label: "Total Plate Count",
      tips_value: { specification: "", test_method: "FDA-BAM" },
      value: "",
      disabled: false,
      display: true,
    },
    yeast_and_mold: {
      type: "input",
      label: "Yeast And Mold",
      tips_value: { specification: "", test_method: "FDA-BAM" },
      value: "",
      disabled: false,
      display: true,
    },
    ecoli: {
      type: "input",
      label: "Ecoli",
      tips_value: { specification: "", test_method: "FDA-BAM" },
      value: "",
      disabled: false,
      display: true,
    },
    salmonella: {
      type: "input",
      label: "Salmonella",
      tips_value: { specification: "", test_method: "FDA-BAM" },
      value: "",
      disabled: false,
      display: true,
    },
    staph_aureus: {
      type: "input",
      label: "Staph Aureus",
      tips_value: { specification: "", test_method: "FDA-BAM" },
      value: "",
      disabled: false,
      display: true,
    },
    total_coliforms: {
      type: "input",
      label: "Total Coliforms",
      tips_value: { specification: "", test_method: "FDA-BAM" },
      value: "",
      disabled: false,
      display: true,
    },
    custom_field: {
      type: "custom_input",
      label: "Custom #:",
      tips_value: { specification: "", test_method: "" },
      value: "",
      disabled: false,
      display: true,
      fields: "",
      attr: "mt_custom_field",
    },
  },
  other_notes: {
    shelflife_number: {
      type: "input",
      label: "Shelflife Number",
      tips_value: "",
      value: "",
      display: true,
      disabled: false,
    },
    shelf_life: {
      type: "input",
      label: "Shelf Life",
      tips_value: " in the original package",
      value: "",
      disabled: false,
      display: true,
    },
    storage: {
      type: "input",
      label: "Storage",
      tips_value: "Preserve in airtight containers in a cool area, dry area.",
      value: "",
      disabled: false,
      display: true,
    },
    package_info: {
      type: "input",
      label: "Package Info",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    notes: {
      type: "input",
      label: "Notes",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
  },
};
