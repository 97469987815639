import * as defaultData from "../../data/DefaultData";

export const TY_P = {
  base_info: {
    product_type: {
      type: "select",
      label: "产品类别",
      tips_value: "",
      options_value: defaultData.ProductTypeData,
      value: "",
      disabled: false,
      display: true,
      eventHandle: "productTypeChange",
    },
    print_title: {
      type: "input",
      label: "标题",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    product_name: {
      type: "input",
      label: "产品名称",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    item_code: {
      type: "input",
      label: "产品编码",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    version_number: {
      type: "input",
      label: "版本号",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    product_description: {
      type: "textarea",
      label: "产品说明",
      tips_value:
        "经有机认证的全谱工艺提取、喷雾干燥的粉末状 ${botanical_name_str }植物提取物。",
      value: "",
      disabled: false,
      display: true,
    },
    physical_properties: {
      type: "select",
      label: "物理形态",
      tips_value: "",
      options_value: defaultData.PhysicalPropertiesData,
      value: "",
      disabled: false,
      display: true,
    },
    document_notes: {
      type: "input",
      label: "文件备注",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    custom_field: {
      type: "custom_input",
      label: "自定义字段",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
      fields: "",
      attr: "custom_field",
    },
  },
  general_specification: {
    inci_name: {
      type: "tag",
      label: "INCI名称",
      tips_value: [],
      value: "",
      disabled: false,
      display: true,
    },
    botanical_name: {
      type: "tag",
      label: "植物学名",
      tips_value: [],
      value: "",
      disabled: false,
      display: true,
    },
    common_other_name: {
      type: "tag",
      label: "通用名/其他名称",
      tips_value: [],
      value: "",
      disabled: false,
      display: true,
    },
    part_used: {
      type: "tag",
      label: "使用部位",
      tips_value: [],
      value: "",
      disabled: false,
      display: true,
    },
    country_of_origin: {
      type: "input",
      label: "原产地",
      tips_value: "中国",
      value: "",
      disabled: false,
      display: true,
    },
    custom_field_before_regular_data: {
      type: "custom_input",
      label: "自定义字段",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
      fields: "",
      attr: "custom_field_before_regular_data",
    },
    percentage: {
      type: "tag",
      label: "百分比",
      tips_value: [],
      value: "",
      disabled: false,
      display: true,
    },
    extraction_ratio: {
      type: "input",
      label: "提取率",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    important_constitue_in_plant: {
      type: "input",
      label: "植物主要成分",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    appearance: {
      type: "input",
      label: "外观",
      tips_value: "粉末",
      value: "",
      disabled: false,
      display: true,
    },
    solubility: {
      type: "input",
      label: "溶解度",
      tips_value: "溶于/可分散于水",
      value: "",
      disabled: false,
      display: true,
    },
    homogeneity: {
      type: "input",
      label: "均匀度",
      tips_value: "完全均匀",
      value: "",
      disabled: false,
      display: true,
    },
    extraction_process: {
      type: "input",
      label: "提取过程",
      tips_value: "可控温度下水萃取，喷雾干燥",
      value: "",
      disabled: false,
      display: true,
    },
    solvent_for_extraction: {
      type: "input",
      label: "提取溶剂",
      tips_value: "水",
      value: "",
      disabled: false,
      display: true,
    },
    carrier: {
      type: "input",
      label: "辅料",
      tips_value: "无",
      value: "",
      disabled: false,
      display: true,
    },
    other_ingredients: {
      type: "input",
      label: "其他成分",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    pesticides: {
      type: "input",
      label: "农药残留",
      tips_value: "符合USP<565>",
      value: "",
      disabled: false,
      display: true,
    },
    certification: {
      type: "input",
      label: "证书",
      tips_value: "Kosher certified, HALAL certified",
      value: "",
      disabled: false,
      display: true,
    },
    custom_field: {
      type: "custom_input",
      label: "自定义字段",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
      fields: "",
      attr: "gs_custom_field",
    },
  },
  analysis: {
    standard1: {
      type: "input",
      label: "标准1",
      tips_value: { specification: "", test_method: "DracoUV-" },
      value: "",
      disabled: false,
      display: true,
    },
    standard2: {
      type: "input",
      label: "标准2",
      tips_value: { specification: "", test_method: "DracoHPLC-" },
      value: "",
      disabled: false,
      display: true,
    },
    standard3: {
      type: "input",
      label: "标准3",
      tips_value: { specification: "", test_method: "" },
      value: "",
      disabled: false,
      display: true,
    },
    mesh_size: {
      type: "select",
      label: "过筛尺寸",
      tips_value: "",
      options_value: defaultData.MeshSizeOptionsData,
      value: "",
      disabled: false,
      display: true,
    },
    loss_on_drying: {
      type: "input",
      label: "干燥失重",
      tips_value: { specification: "<6%", test_method: "Draco-1.1.1.0" },
      value: "",
      disabled: false,
      display: true,
    },
    bulk_density: {
      type: "input",
      label: "堆密度",
      tips_value: { specification: "0.25-0.75 g/mL", test_method: "USP <616>" },
      value: "",
      disabled: false,
      display: true,
    },
    identification: {
      type: "input",
      label: "HPTLC鉴定",
      tips_value: { specification: "合格", test_method: "HPTLC" },
      value: "",
      disabled: false,
      display: true,
    },
    total_heavy_metals: {
      type: "input",
      label: "重金属",
      tips_value: { specification: "<10 ppm", test_method: "USP<233>" },
      value: "",
      disabled: false,
      display: true,
    },
    arsenic: {
      type: "input",
      label: "砷",
      tips_value: { specification: "", test_method: "USP<232>" },
      value: "",
      disabled: false,
      display: true,
    },
    cadmium: {
      type: "input",
      label: "镉",
      tips_value: { specification: "", test_method: "USP<232>" },
      value: "",
      disabled: false,
      display: true,
    },
    lead: {
      type: "input",
      label: "铅",
      tips_value: { specification: "", test_method: "USP<232>" },
      value: "",
      disabled: false,
      display: true,
    },
    mercury: {
      type: "input",
      label: "汞",
      tips_value: { specification: "", test_method: "USP<232>" },
      value: "",
      disabled: false,
      display: true,
    },
    color: {
      type: "input",
      label: "颜色",
      tips_value: { specification: "标准", test_method: "视觉" },
      value: "",
      disabled: false,
      display: true,
    },
    odor: {
      type: "input",
      label: "气味",
      tips_value: { specification: "天然淡植物气味", test_method: "感官评定" },
      value: "",
      disabled: false,
      display: true,
    },
    taste: {
      type: "input",
      label: "味道",
      tips_value: { specification: "符合标准", test_method: "感官评定" },
      value: "",
      disabled: false,
      display: true,
    },
    custom_field: {
      type: "custom_input",
      label: "自定义字段",
      tips_value: { specification: "", test_method: "" },
      value: "",
      disabled: false,
      fields: "",
      display: true,
      attr: "an_custom_field",
    },
  },
  microbiological_tests: {
    total_plate_count: {
      type: "input",
      label: "细菌总数",
      tips_value: { specification: "", test_method: "FDA-BAM" },
      value: "",
      disabled: false,
      display: true,
    },
    yeast_and_mold: {
      type: "input",
      label: "酵母和霉菌",
      tips_value: { specification: "<100 cfu/g", test_method: "FDA-BAM" },
      value: "",
      disabled: false,
      display: true,
    },
    ecoli: {
      type: "input",
      label: "大肠杆菌",
      tips_value: { specification: "阴性", test_method: "FDA-BAM" },
      value: "",
      disabled: false,
      display: true,
    },
    salmonella: {
      type: "input",
      label: "沙门氏菌",
      tips_value: { specification: "阴性", test_method: "FDA-BAM" },
      value: "",
      disabled: false,
      display: true,
    },
    staph_aureus: {
      type: "input",
      label: "金色葡萄球菌",
      tips_value: { specification: "阴性", test_method: "FDA-BAM" },
      value: "",
      disabled: false,
      display: true,
    },
    total_coliforms: {
      type: "input",
      label: "大肠菌群总数",
      tips_value: { specification: "<10 MPN/g", test_method: "FDA-BAM" },
      value: "",
      disabled: false,
      display: true,
    },
    custom_field: {
      type: "custom_input",
      label: "自定义字段",
      tips_value: { specification: "", test_method: "" },
      value: "",
      disabled: false,
      fields: "",
      display: true,
      attr: "mt_custom_field",
    },
  },
  other_notes: {
    shelflife_number: {
      type: "input",
      label: "保质期(*个月)",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    shelf_life: {
      type: "input",
      label: "保质期备注",
      tips_value: "产品对热和潮湿敏感。",
      value: "",
      disabled: false,
      display: true,
    },
    storage: {
      type: "input",
      label: "储存条件",
      tips_value: "避光，密封，在干燥处保存。",
      value: "",
      disabled: false,
      display: true,
    },
    package_info: {
      type: "input",
      label: "包装",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    is_show_note: {
      type: "input",
      label: "is_show_note",
      tips_value: false,
      value: "",
      disabled: false,
      display: false,
    },
    notes: {
      type: "input",
      label: "备注",
      tips_value: "",
      value: "",
      disabled: false,
      display: false,
    },
  },
};
